.championships {
	flex-grow: 1;

	&-table {
		width: unset !important;

		td:first-child,
		th:first-child,
		td:nth-child(7),
		th:nth-child(7) {
			text-align: center;
		}
	}
	
	.dialog {
		td, th {
			padding: 8px;
		}

		.tags .autocomplete-results {
			position: fixed;
			left: unset;
			top: unset;
			width: 200px;
			max-height: 30vh;
			overflow-y: scroll;
		}
	}
}
