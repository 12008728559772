.medias
{
	flex-grow: 1;
	.upload-section:not(:empty) {
		margin: 12px 4px;
	}
	position: relative;
	
	&.drop:after {
		content: "Upload Files";
		display: flex;
		font-size: 30px;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: -8px;
		right: -8px;
		top: -8px;
		bottom: -8px;
		background-color: rgba(60,0,0,50%);
	}
}

.media-list
{
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	.media-item {
		width: calc(100% / 10);
		@media (max-width: 1500px) {
			width: calc(100% / 9);
		}
		@media (max-width: 1250px) {
			width: calc(100% / 7);
		}
		@media (max-width: 1000px) {
			width: calc(100% / 6);
		}
		@media (max-width: 750px) {
			width: calc(100% / 4);
		}
		@media (max-width: 500px) {
			width: calc(100% / 2);
		}

		cursor: pointer;
		overflow: hidden;
		position: relative;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
		}


		.image-container {
			margin: 4px;
			position:  absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			color: white;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			background-color: #222;
			border: 1px solid var(--border-color);
		}
		
		&.selected
		{
			.image-container {
				outline: var(--pto-red) solid 3px;
			}
		}
	}
}

.upload-jobs 
{
	width: 100%;
	.job 
	{
		width: 100%;
		margin-bottom: 4px;
		background-color: #111;
		color: white;
		display: flex;
		justify-content: space-between;
		padding: 4px 12px;
		.job-name {
			font-weight: bold;
		}
		.job-progress {

		}
	}
}

.editing {
	overflow-y: auto;
	padding-left: 12px;
	height: 100%;
	width: 400px;
	flex-shrink: 0;
	table {
		line-height: 20px;
		width: 100%;
		th { text-align: right; width: 100px;}
		th, td { padding: 4px 4px;  vertical-align: top; }
	}
}

.dialog-edit-picture
{
	.dialog{
		max-width: unset;
		width: 95vw;
		height: 95vh;
		.body {
			overflow-y: hidden;
			height: 100%;
		}
	}
	.image-container {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		picture {
			width: 100%;
			height: 100%;
			img { 
				width: 100%; 
				height: 100%; 
				object-fit: contain;
			}
		}
		.image {
			display: contents;
		}
	}
	.editing {
		border-left: 1px solid rgba(255,255,255,0.2);
	}

}

