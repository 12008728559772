.season-info {
	flex-grow: 1;

	td,
	th {
		padding: 4px 8px;
	}

	.participants-table {
		width: unset !important;

		th, td {
			padding: 8px 8px;
		}

		th:nth-child(3),
		td:nth-child(3),
		th:nth-child(4),
		td:nth-child(4) {
			text-align: center;
		}
	}

	.tags .autocomplete-results {
		position: fixed;
		left: unset;
		top: unset;
		width: 200px;
		max-height: 30vh;
		overflow-y: scroll;
	}
}
