.races {
	flex-grow: 1;

	.filter-select {
		width: 144px;
	}

	td, th { 
		padding: 8px 8px; text-align: center; 
	}
}

.races-table {
	tr {  
		border-bottom: 1px solid var(--border-color);
	}

	td:nth-child(2), th:nth-child(2) { 
		text-align: left; 
	}

	td:nth-child(5), th:nth-child(5) {
		max-width: 120px;
	}

	td:nth-child(6), th:nth-child(6) {
		max-width: 120px;
	}
} 
