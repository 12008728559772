.season-participant-info {
   flex-grow: 1;

   td,
   th {
      padding: 4px 8px;
   }

	.autocomplete-results {
		max-height: 30vh;
		min-width: 200px;
		overflow-y: scroll;
	}

	img {
		max-height: 200px;
	}
}
