.series {
	flex-grow: 1;
	td,
	th {
		text-align: center;
	}

	&-table {
		width: unset !important;
		
		td:nth-child(2),
		th:nth-child(2) {
			text-align: left;
		}
	}

	.dialog {
		td, th {
			padding: 8px;
		}
	}
}
