.tags
{
	.autocomplete {
		position: relative;
		top: 0;
		left: 0;

		height: 28px;
		
		input {
			display: block;
			width: 100%;
			//border: none;
			//background-color: var(--select-color) !important;
			color: white;
		}
		
		.autocomplete-results {
			z-index: 300;
			position: absolute;
			top: calc(100% + 4px);
			left: 0;
			width: 100%;
			height: unset;
			background: var(--select-color);

			& > .autocomplete-result {
				cursor: pointer;
				padding: 0.25rem 0.5rem;
				
				&.active {
					border-radius: 4px;
					background: #444;
				}
			}
		}
	}
	.tag-list {
		display: flex;
		flex-wrap: wrap;

		.tag {
			height: 24px;
			line-height: 24px;
			padding: 0 8px;
			margin: 2px 2px;
			border-radius: 2px;
			background-color: #606162;
			vertical-align: middle;

			.delete {
				color: #111;
				&:hover { color: red; }
			}
			.delete:before {
				margin-left: 12px;
				content: "✕";
				cursor: pointer;

			}
		}
	}
}

