.videos {
	width: 100%;
}

.videos-table {
	width: 100%;
	td { padding: 8px 4px; border-bottom: 1px solid rgba(0,0,0,0.2); }
}

.videos-card {
	max-width: 400px;	
}

.videos-thumnail
{
	height: 60px;
	//margin: 8px 0;
}


.videos-icon {
	width: 36px;
}

.videos-date {
	font-size: 12px;
	color: #888;
}

.videos-title {
	font-weight: bold;
}

.videos-description {
	color: #888;
}

.videos-author {
	margin-top: 4px;
	font-size: 12px;
	color: #888;
}

.video-player {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	
	video {
		max-width: 90%;
	}

	.video-player-close {
		position: absolute;
		right: 32px;
		top: 32px;
		
		background-color: rgba(255,255,255,0.3);
		width: 40px;
		height: 40px;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		i {
			font-size: 24px;
		}
	}

}
