body {
	&.members { 
		background: #1e1e1e; 
		display: flex;
	}
}

.profile-app
{
	//padding-top: env(safe-area-inset-top);
	//padding-bottom: env(safe-area-inset-bottom);
	display: flex;
	width: 100%;
	height: 100%;

	//&[keyboard] { padding-bottom: 0; }
}

.emoji
{
	&.haha:before { content: "😂"; }
	&.shocked:before { content: "😲"; }
	&.sad:before { content: "😢"; }
	&.heart:before { content: "❤️"; }
	&.fire:before { content: "🔥"; }
	&.thumb_up:before { content: "👍"; }
	&.thumb_down:before { content: "👎"; }
}


.profile-main
{
	position: relative;
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
	height: fit-content;
	overflow-x: hidden; //visible;
	overflow-y: hidden; //visible;


	//padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
	//padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */

	//border-left: 1px solid rgba(255,255,255,0.2);
	//border-right: 1px solid rgba(255,255,255,0.2);

	a:hover { color: unset; }

	.back, .down {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
		cursor: pointer;
		width: 45px;
		height: 45px;
		border-radius: 50px;

		&:hover {
			background-color: #333;			
		}

		&:before {
			line-height: 35px;
			font-size: 35px;
			color: var(--pto-red);
			content: "arrow_back";
			font-family: 'Material Icons Round';
			font-weight: normal;
			font-style: normal;
			letter-spacing: normal;
			text-transform: none;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			-webkit-font-feature-settings: 'liga';
			-webkit-font-smoothing: antialiased;		
		}
	}

	.down:before { content: "arrow_downward"; }
	.down:after { content: unset; }

	ul {
		margin: 0;
		padding: 0;

		a {
			-webkit-touch-callout: none;
		}

		li {
			padding: 8px 32px;
			margin: 0;
			display: flex;
			//border-bottom: 1px solid rgba(255,255,255,0.2);
			cursor: pointer;
			justify-content: center;
			align-items: center;

			justify-content: space-between;

			background-color: #323334;
			margin: 8px;
			border-radius: 8px;

			//-webkit-tap-highlight-color: rgba(0,255,0,1);
			&:active {
				background-color: #424344;
			}
			&:focus {
				background-color: #424344;
			}
			/*&:focus-visible {
				background-color: red;
			}
			&:target {
				background-color: blue;
			}*/
			&:focus-within {
				background-color: #424344;
			}

			transition: background-color 250ms;
			&:hover {
				box-shadow: 0px 0px 10px rgba(0,0,0,.25);
				background-color: #3E4044;
			}

			& > div { 
				display: flex;
				.text-grey { display: flex; align-items: center; width: 80px; min-width: 80px; }
				.select { width: 100%; display: flex; align-items: center; }
				input[type="text"] { font-size: 14px; }
			}
			/*:first-child {
				display: flex;
				:first-child { min-width: 100px; width: 100px; display: flex; align-items: center; }
				:nth-child(2) { flex-grow: 1; }
			}*/

			&.head { background-color: transparent; }

			.text-grey {
				font-style: italic;
				color: #ccc;
			}

			&.goto {
				font-size: 20px;
			}

			&.edit:after, &.goto:not(:empty):after {
				line-height: 20px;
				font-size: 20px;
				float: right;
				color: var(--pto-red);
				content: "chevron_right";
				font-family: 'Material Icons Round';
				font-weight: normal;
				font-style: normal;
				letter-spacing: normal;
				text-transform: none;
				white-space: nowrap;
				word-wrap: normal;
				direction: ltr;
				-webkit-font-feature-settings: 'liga';
				-webkit-font-smoothing: antialiased;		
			}

			&.edit:after {
				content: "edit";
				content: "";
			}

			& > div { flex-grow: 1; max-width: 100%; overflow-x: hidden; } 

			.select:after {
				content: "";
			}

			select[data-selected="0"], select:not([data-selected]) {
				color: #888;
			}

			.value { 
				height: unset;
				width: calc(100% - 6px);
				margin: 3px;
				padding: 1px 8px;
				background-color: transparent !important;
				color: unset;
				border: 0;
				font-size: 1.25rem; 
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
  				&:focus {
  					outline: 0;
  					box-shadow: 0 0 0 1px rgb(225 83 97 / 50%);
  				}
  			}
		}
	}

	.settings {
		//min-height: 100%;
		//height: fit-content;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		//padding-top: 44px;

		/*&:after {
			background-color: green;
			width: 100%;
			//position: absolute;
			//top: 50%;
			left: 0;
			content: "";
			height: env(safe-area-inset-bottom);

		}*/

		//padding-bottom: calc(env(safe-area-inset-bottom));



	//padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
	//padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */

		//position: fixed;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		transition: opacity 250ms, visibility 250ms, transform 250ms;
		opacity: 1;
		background-color: #1e1e1e; //var(--background);

		ul {
			flex-grow: 1;
			flex-shrink: 1;
			overflow-y: auto;
		}

		/*&:not([root]):before
		{
			transition: background-color 250ms;
			content: " ";
			background-color: rgba(0,0,0,0.5);
			position: absolute;
			height: 100%;
			top: 0;
			bottom: 0;
			left: -100%;
			width: 100%;
		}*/

		&:not([stack="0"])
		{
			opacity: 0;
			transform: scale(1.15);
		}

		&[hide]{
			opacity: 0;
			visibility: hidden;
			transform: scale(0.85);
			/*&:before {
				background-color: rgba(0,0,0,0);
				//content: unset;
			}*/
			&[root]{
				transform: unset;
			}
			//display: none !important;
		}

		.settings-title {
			padding: 0px 32px;
			height: 60px;
			font-size: 1.75rem;
			border-bottom: 1px solid rgba(255,255,255,0.2);
			display: flex;
			align-items: center;
			//position: sticky;
			//top: 44px;
			width: 100%;
			background-color: #1e1e1e; //#28292a;
			//z-index: 10;
			flex-shrink: 0;
			//background-color: red;

    		img { 
    			width: 70px;
    		}
    	}
    	.logo {
    		width: 100%;
    		display: flex;
    		justify-content: center;
    		img {
    			max-width: 200px;
    			width: 100%;
    			height: 100%;
    			object-fit: contain;
    		}
    	}

		.home-page {
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
			flex-wrap: wrap;

			a {
				//border-radius: 100px;
				//-webkit-tap-highlight-color: red;
				.goto { 
					position: relative;
					margin: 20px;
					font-size: 14px;
					display: flex;
					flex-direction: column;
					align-items: center;
					i {
						transition: background-color 100ms;
						border-radius: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #323334;
						font-size: 40px;
						flex-grow: 0;
						flex-shrink: 0;
						width: 80px;
						height: 80px;
					}
					.unread-badge {
						position: absolute;
						top: 0;
						right: 0;
					}
				}
				&:active, &:focus, &:hover { 
					.goto i {
						background-color: #555; 
					}
				}
			}
			
		}
	}
}

.selector {
	position: fixed;
	display: flex;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.5);
	z-index: 200;
	justify-content: center;
	align-items: center;


	.selector-container
	{
		width: 100%;
		max-width: 400px;
		background-color: #222;
		height: calc(100% - 32px);
		padding: 16px 16px 0 16px;
		display: flex;
		flex-direction: column;
		border-radius: 16px;


		@media (max-width: 768px) {
			border-radius: 0;
			height: 100%;
			max-width: unset;
		}

		.back {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			margin-right: 16px;
			cursor: pointer;
			width: 45px;
			height: 45px;
			border-radius: 50px;

			&:hover {
				background-color: #333;			
			}

			&:before {
				line-height: 35px;
				font-size: 35px;
				color: var(--pto-red);
				content: "arrow_back";
				font-family: 'Material Icons Round';
				font-weight: normal;
				font-style: normal;
				letter-spacing: normal;
				text-transform: none;
				white-space: nowrap;
				word-wrap: normal;
				direction: ltr;
				-webkit-font-feature-settings: 'liga';
				-webkit-font-smoothing: antialiased;		
			}

		}

		.selector-title {
			display: flex;
			align-items: center;
			//text-align: center;
			flex-shrink: 0;

			//padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
			//padding-top: env(safe-area-inset-top); /* iOS 11.2 */

		}

		input {
			margin-bottom: 8px;
			flex-shrink: 0;
		}

		ul {
			margin: 0;
			padding: 0;
			flex-grow: 1;
			overflow-y: auto;
			position: relative;

			//padding-bottom: constant(safe-area-inset-bottom);
			//padding-bottom: env(safe-area-inset-bottom);

			li {
				padding: 8px 32px;
				margin: 0;
				display: flex;
				cursor: pointer;
				justify-content: center;
				align-items: center;

				justify-content: space-between;

				background-color: #323334;
				margin: 8px;
				border-radius: 8px;

				&:active {
					background-color: #424344;
				}
				&:focus {
					background-color: #424344;
				}
				&:focus-within {
					background-color: #424344;
				}

				transition: background-color 250ms;
				&:hover {
					box-shadow: 0px 0px 10px rgba(0,0,0,.25);
					background-color: #303030;
				}
				padding: 8px 16px;
				margin: 8px 0;

				&[selected] { background-color: var(--pto-red); }
			}
		}
	}
}

.conversation-list
{
	.entry {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		position: relative;

		&[unread]::before {
			content: "";
			width: 12px;
			height: 12px;
			position: absolute;
			background-color: var(--pto-red);
			left: 12px;
			top: calc(50% - 6px);
			border-radius: 8px;
		}
		&[unread]
		{
			background: linear-gradient(#D10B0C30, #D10B0C30);
		}

		.user {
			font-weight: bold;
		}

		.preview, .time {
			color: #888;
		}
	}
}

.conversation-container {
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.reactions-background {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		background-color: rgba(0,0,0,0.8);

		transition: opacity 250ms, visibility 250ms;
		opacity: 0;
		visibility: hidden;

		&[show] {
			opacity: 1;
			visibility: unset;
		}

		.reactions {
			font-size: 24px;
			position: absolute;
			padding: 8px 16px;
			background-color: #444;
			border-radius: 16px;
			.emoji {
				cursor: pointer;
				padding: 4px 8px;
				border-radius: 8px;
				&:hover {
					background-color: #666;
				}
			}
		}
	}
}

.conversation {
	overflow-y: auto;
	//scroll-behavior: smooth;
	//-webkit-overflow-scrolling: touch;
	//height: 100%;
	//background-color: #456;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	//position: relative;


	.message {
		padding: 8px 12px;
		width: 100%;
		overflow: hidden;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		.core {
			width: 65%;
			display: flex;
			align-items: center;

			.body {
				flex-shrink: 0;
				//border: 1px solid rgba(255,255,255,0.2);
				border-top-left-radius: 16px;
				border-top-right-radius: 16px;
				border-bottom-right-radius: 16px;
				background-color: #424344;
				padding: 8px 12px;
				white-space: pre-wrap;
				width: fit-content;

				&[highlight] {
					z-index: 1;
					pointer-events: none;
				}

				&[image] {
					position: relative;
					width: 100%;					

					overflow: hidden;
					padding: 0;
					background-color: transparent;
					img { 
						position: absolute;
						top: 0;
						width: 100%; 
						height: auto; 
					}
				}
				
				a { text-decoration: underline; }
			}

			.actions {
				flex-shrink: 0;
				width: 40px;
				user-select: none;
				visibility: hidden;
				display: flex;
				align-items: center;
				
				.action {
					cursor: pointer;
					color: #888;
					padding: 6px;
					border-radius: 32px;
					&:hover {
						background-color: #383838;
					}
				}
			}
		}
			
		.reaction-container {
			.reaction {
				cursor: pointer;
				border: 1px solid var(--border-color);
				padding: 4px 8px;
				border-radius: 16px;
				margin: 2px 2px 2px 2px;
				display: flex;
				align-items: center;
				color: #888;
				font-size: 12px;
				width: fit-content;
				&:hover {
					background-color: #444;
				}
			}
			.emoji {
				margin-right: 2px;
				font-size: 16px;
				line-height: 16px;
			}
		}



		.date {  
			color: #888;
			font-size: 11px;
			margin: 0 2px;
		}

		&[mine]
		{
			.core {
				flex-direction: row-reverse;
			}
			.body {
				background-color: var(--pto-red);
				border-bottom-left-radius: 16px;
				border-bottom-right-radius: unset;
			}
			align-items: flex-end;
			align-self: flex-end;
		}

		&:hover {
			.actions { visibility: unset; }
		}
	}
}

.conversation-input {
	display: flex;
	align-items: flex-end;
	border-top: 1px solid rgba(255,255,255,0.2);
	padding: 8px;
	padding-right: 0px;
	flex-shrink: 0;
	textarea { 
		line-height: 20px;
		padding: 6px 8px;
		width: 100%; 
		resize: none; 
		max-height: 30vh;
	}



	//padding-bottom: env(safe-area-inset-bottom);

	//margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
	//margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */


	.send-container
	{
		display: flex;
		justify-content: center;
		width: 60px;
		.btn { width: 40px; height: 34px; }
	}

}

.message-new-users {
	.dialog {
		height: 90vh;
		max-height: 600px;
		width: 90vw;
		max-width: 500px;

		//padding-bottom: env(safe-area-inset-bottom);

		background-color: #1e1e1e;

		@media (max-width: 768px) {
			border-radius: 0;
			height: 100%;
			width: 100%;
			max-width: unset;
			max-height: unset;

			//padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
			//padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
		}

		.h4 { text-transform: unset; }
	}

	.body {
		flex-grow: 1;
		padding: 0 !important;

		.selection {
			border-bottom: 1px solid var(--border-color);
		}		
		.selected {
			display: flex;
			flex-wrap: wrap;

			.selected-user {
				border-radius: 4px;
				border: 1px solid rgba(255,255,255,0.5); //white; //var(--border-color);
				background-color: #444;
				background-color: var(--pto-red);
				padding: 4px 8px;
				margin: 4px 4px 4px 0px;
				cursor: pointer;

				&:hover {  background-color: #222; }
					
				&:after {
					content: "✕";
					padding: 4px;
					margin-left: 8px;
				}
			}
		}

		.user {
			padding: 12px 1.5rem;
			border-bottom: 1px solid var(--border-color);
			cursor: pointer;
			&:hover {
				background-color: #333;
			}
		}
	}

	.ox-modal {
		display: flex;
		visibility: hidden;
		opacity: 0;
		transition: opacity 250ms, visibility 250ms;
		padding-top: env(safe-area-inset-top);
		padding-bottom: env(safe-area-inset-bottom);
		@media (max-width: 768px) {
			background-color: transparent;
		}
		
		.dialog { 
			transition: transform 250ms;
			transform: translateY(100px);  
		}
		&.show {
			visibility: unset;
			opacity: 1;
			.dialog { transform: unset;  }
		}
	}

}

.unread-badge
{
	margin-left: 4px;
	font-size: 14px;
	border-radius: 16px;
	padding: 2px 8px;
	background-color: var(--pto-red);
}

.warning-offline {
	position: absolute;
	left: 50%;
	top: 10%;
	transform: translateX(-50%);
	padding: 16px;
	border: 1px solid white;
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	border-radius: 8px;
	width: 100%;
	max-width: 80%;
	text-align: center;
	visibility: hidden;

	&[show]
	{
		visibility: unset;
	}
}


.profile-media-viewer {
		
	display: flex;
	width: 100%;
	height: 100%;
	position: absolute;
	visibility: hidden;
	opacity: 0;

	transition: opacity 250ms, visibility 250ms;

	&[show] {
		opacity: 1;
		visibility: unset;
	}


	background-color: #202020;

	.ui-container {
		position: absolute;
		width: 100%;
		height: 100%;

		.close-viewer { 
			font-size: 20px;
			line-height: 20px;
			cursor: pointer;
			color: white;
			position: absolute;
			width: 30px;
			height: 30px;
			left: 8px;
			top: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.media-container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		img { 
			object-fit: contain;
			width: 100%; 
			height: 100%;
		}
	}

}