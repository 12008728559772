.ranking-table
{
	td { padding: 6px 8px; }
	td:nth-child(1), th:nth-child(1) { text-align: right; width: 200px; }
	td:nth-child(2), th:nth-child(2) { text-align: center; width: 60px; }
	td:nth-child(3), th:nth-child(3) { text-align: center; width: 60px; }
	td:nth-child(4), th:nth-child(4) { text-align: center; width: 60px; }
	td:nth-child(5), th:nth-child(5) { text-align: center; width: 60px; }
	td:nth-child(6), th:nth-child(6) { text-align: center; width: 60px; }
	td:nth-child(7), th:nth-child(7) { text-align: left; width: 200px; }

	td {
		&[up] {
			color: MediumSeaGreen;
			&:before { content: "+"; }
		}
		&[down] {
			color: Tomato;
		}
	}

	tr {
		&[hunt] {
			background-color: rgba(255,0,0,0.1); 
		}
	}

}