.tiers {
	flex-grow: 1;

	&-table {
		width: unset !important;

		td:first-child,
		th:first-child,
		td:nth-child(4),
		th:nth-child(4),
		td:nth-child(5),
		th:nth-child(5),
		td:nth-child(6),
		th:nth-child(6) {
			text-align: center;
		}

		// Description column
		td:nth-child(3) {
			max-width: 38vw;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	
	.dialog {
		td, th {
			padding: 8px;
		}
	}
}
