.race-info
{
	flex-grow: 1;
	//margin: 20px;
	td, th { padding: 4px 8px; }
}

.race-results
{
	text-align: center;
	td:nth-child(2), th:nth-child(2) { text-align: left; }
	.points { font-size: unset; }
} 	

.participants
{
	.remove {
		cursor: pointer;
		&:hover { color: var(--pto-red); }
	}
}