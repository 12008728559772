.brand-info {
	flex-grow: 1;

	td,
	th {
		padding: 4px 8px;
	}

	.image-container {
		margin-bottom: .75rem;

		img {
			max-width: 160px;
			max-height: 160px;
		}
	}
}
