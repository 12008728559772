.series-redirects {
	flex-grow: 1;

   &__table {
      td,
      th {
         padding: 8px 8px;
      }

      tr:first-child > td:first-of-type,
      tr > td:first-of-type,
      tr > td:nth-last-child(2) {
         text-align: center;
      }
      
      tr {
         border-bottom: 1px solid var(--border-color);
      }

		td.active,
		td.broke {
			text-transform: uppercase;
		}

      td.active {
			color: green;
      }

      td.broke {
         color: red;
      }
   }
}
