.brands {
	flex-grow: 1;
	.searchadd
	{
		display: flex;
		flex-grow: 1;
		align-items: center;
		input { max-width: 200px; }
	}

	.brands-table
	{
		a { cursor: pointer; }
		tr { border-bottom: 1px solid var(--border-color); }
		.image-container
		{
			display: flex;
			justify-content: center;
			height: 50px;
			width: 100px;
			margin: 4px 20px;

			img { min-height: 50px; height: 50px; max-width: 100px; object-fit: contain; }		
		}
	}
}
