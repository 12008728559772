.news {
	width: 100%;
}

.news-table {
	width: 100%;
	td { padding: 8px 4px; vertical-align: top; border-bottom: 1px solid rgba(0,0,0,0.2); }
}

.news-card {
	max-width: 400px;	
}

.news-thumnail
{
	height: 60px;
	//margin: 8px 0;
}


.news-icon {
	width: 36px;
}

.news-date {
	font-size: 12px;
	color: #888;
}

.news-title {
	font-weight: bold;
}

.news-description {
	color: #888;
}

.news-author {
	margin-top: 4px;
	font-size: 12px;
	color: #888;
}
