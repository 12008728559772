.seasons {
	flex-grow: 1;

	&-table {
		width: unset !important;

		td:first-child,
		th:first-child,
		td:nth-child(5),
		th:nth-child(5),
		td:nth-child(6),
		th:nth-child(6),
		td:nth-child(7),
		th:nth-child(7) {
			text-align: center;
		}
	}
	
	.dialog {
		td, th {
			padding: 8px;
		}
	}
}
