.athletes
{
	flex-grow: 1;
}

.add_athlete {
	cursor: pointer;
	&:hover { color: var(--pto-red); }
}

.table-add-athlete {
	width: 100%;
	td { padding: 2px 4px;}
}