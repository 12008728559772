
.please-wait
{
	font-size: 40px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 5000;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0,0,0,0.6);

}