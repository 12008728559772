.tabs {
	display: flex;
	margin-bottom: 12px;

	.tab {
		cursor: pointer;
		text-transform: uppercase;
		font-weight: bold;
		padding: 6px 30px;
		border-bottom: 3px solid transparent;

		&[selected="true"] {
			color: var(--pto-red);
			border-color: var(--pto-red);
		}
	}
}

.athlete-info {
	flex-grow: 1;

	td,
	th {
		padding: 4px 8px;
	}

	.profile-pic {
		width: 160px;

		img {
			width: 100%;
		}
	}

	.athlete-table {
		th:nth-child(1) {
			text-align: right;
		}

		td {
			&:nth-child(2) {
				min-width: 300px;
			}

			&.tinymce-wrap {
				min-width: 500px;
				text-align: right;

				.btn {
					&[disabled] {
						pointer-events: none;
						cursor: not-allowed;
						opacity: 0.5;
					}
				}
			}
		}
	}

	.equipment-table {
		th:nth-child(1) {
			text-align: right;
		}

		td {
			min-width: 200px;
		}
	}

	.social-table {
		th:nth-child(1) {
			text-align: right;
		}

		td:nth-child(2) {
			min-width: 300px;
		}

		input[type='url']:invalid {
			background-color: var(--pto-red);
		}
	}

	.sponsor-add {
		padding: 8px 0;

		input {
			max-width: 200px;
		}
	}

	.sponsor-table {
		width: 100%;

		tr { border-bottom: 1px solid var(--border-color); }

		td {
			&:nth-child(1) {
				width: 40px;
			}

			&:nth-child(2) {
				width: 120px;
			}

			&:last-child {
				width: 50px;
			}
		}

		.image-container {
			display: flex;
			justify-content: center;
			height: 50px;
			width: 100px;
			margin: 4px 20px;

			img {
				min-height: 50px;
				height: 50px;
				max-width: 100px;
				object-fit: contain;
			}
		}
	}

	.results-table {
		max-width: 1000px;
		width: 100%;
		text-align: center;

		td:nth-child(4),
		th:nth-child(4) {
			text-align: left;
		}

		td {
			&:nth-child(1),
			&:nth-child(2) {
				width: 80px;
			}

			&:nth-child(3) {
				width: 60px;
			}

			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(9) {
				width: 80px;
			}

			&:nth-child(10) {
				width: 120px;
			}
		}

		.year {
			border-bottom: 1px solid var(--border-color);
			text-align: left;

			&:not(:first-child) {
				h4 {
					margin-top: 20px;
				}
			}
		}
	}

	.pic-submission {
		display: flex;

		img {
			cursor: pointer;
			width: 160px;
			height: 160px;
			object-fit: contain;
		}

		&:before {
			content: "Submission ->";
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 1rem;
		}
	}
}


